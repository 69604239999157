<template>
  <div>
    <van-nav-bar 
      title="新建地址" 
      left-arrow 
      @click-left="onClickLeft" />

    <van-field 
      v-model="form.userName" 
      center label="收货人"
      placeholder="请填写收货人姓名" 
      input-align="right" 
      style="border-bottom:1px solid rgba(0, 0, 0, 0.1);"/>

    <van-field 
      v-model="form.telNum" 
      center type="tel" 
      label="手机号码" 
      placeholder="请填写收货人手机号" 
      input-align="right" 
      style="border-bottom:1px solid rgba(0, 0, 0, 0.1);"/>

    <van-field
      input-align="right"
      center
      readonly
      clickable
      label="所在地区"
      :value="areaText"
      placeholder="请选择所在地区"
      @click="showPicker = true"
      style="border-bottom:1px solid rgba(0, 0, 0, 0.1);"
    />

    <van-popup v-model="showPicker" round position="bottom">
      <van-area 
        title="请选择所在地区" 
        :area-list="areaList" 
        @cancel="showPicker = false"
        @confirm="onConfirm"/>
    </van-popup>

    <van-field
      v-model="form.detailInfo"
      rows="1"
      autosize
      label="详细地址"
      type="textarea"
      placeholder="街道，楼牌号等"
      style="border-bottom:1px solid rgba(0, 0, 0, 0.1);"
      input-align="right"
      center/>

    <van-cell title="设置默认地址" center label="提醒，每次下单会默认推荐使用该地址">
      <template #right-icon>
        <van-switch 
          v-model="checked" 
          size="12px"  
          active-color="#39C15B" 
          inactive-color="#dcdee0" 
          @change="isDefault"/>
      </template>
    </van-cell>

    <div @click="save">
      <van-goods-action>
        <van-goods-action-button color="#E54320" type="primary" text="提交" />
      </van-goods-action>
    </div>

    <loading :loading="loading" @closeLoading="closeLoading" />
  </div>
</template>

<script>
import { addAddress } from '@/api/shopAddAddress/shopAddAddress'
import { areaList } from '@vant/area-data'
import { Toast } from "vant"
import Loading from './components/Loading'
import { mapMutations } from 'vuex'

export default {
  components: { Loading },

  data() {
    return {
      form: {
        userName: '',
        telNum: '',
        provinceName: '',
        cityName: '',
        countyName: '',
        detailInfo: '',
        isDefault: 0,
        delFlag: 0,
      },
      areaText:'',
      areaList,
      showPicker: false,
      checked: false,
      loading: false
    }
  },

  mounted () {
    if(this.$route.query.address!=null)
    {
      this.form = JSON.parse(this.$route.query.address)
      this.areaText = this.form.provinceName + this.form.cityName + this.form.countyName
      this.checked = this.form.isDefault == 1 ? true : false
      this.title = '编辑收货地址'
    }
  },
  methods: {
    ...mapMutations(['zhuanpan/set_list_pan']),

    onClickLeft () {
      this.$router.go(-1)
      this['zhuanpan/set_list_pan'](true)
    },

    onConfirm (value) {
      this.form.provinceName = value[0].name
      this.form.cityName = value[1].name
      this.form.countyName = value[2].name
      this.areaText = value[0].name + value[1].name + value[2].name
      this.showPicker = false
    },

    isDefault () {
      if (this.checked){
        this.form.isDefault = 1
      }
      else{
        this.form.isDefault = 0
      }
    },

    save () {
      if(this.form.userName == ''){
        Toast('请填写收货人姓名')
        return
      }
      if(this.form.telNum == ''){
        Toast('请填写收货人手机号')
        return
      }
      if(this.form.telNum.length != 11){
        Toast('请输入11位手机号码')
        return
      }
      if(this.form.provinceName == ''){
        Toast('请选择所在地区')
        return
      }
      if(this.form.detailInfo == ''){
        Toast('请填写详细地址')
        return
      }
      this.addAddress()
    },

    addAddress () {
      this.loading = true
      addAddress(this.form).then((res) => {
        if (res.data.code === 0) {
          this.loading = false
          this.onClickLeft()
        }
      }).catch(() =>{
        Toast('服务繁忙')
      })
    },

    closeLoading () {
      this.closeLoading = false
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .van-goods-action-button{
  background-color: red;
}
</style>